<template functional>
    <ul class="list-unstyled">
        <li class="media py-3 align-items-stretch"
            v-for="{ _id, images, title, subtitle, prices, quantity, skus, session, date, branch, } in props.items"
            v-bind:key="_id">
            <div class="item-image-wrapper mr-2"
                 v-if="images.length > 0">
                <img class="d-block mx-auto item-image"
                     v-bind:src="`${ parent.$config.apiUrl }/${ parent.$config.apiVersion }/shop/product/image/${ images[0] }`"
                     v-bind:alt="`${ title[parent.$i18n.locale] } | ${ subtitle[parent.$i18n.locale] }`">
            </div>

            <div class="media-body">
                <div class="row no-gutters h-100 position-relative">
                    <div class="col-md-8 d-flex flex-column justify-content-center">
                        <h5 class="text-right text-md-left mt-0 mb-1"
                            v-html="title[parent.$i18n.locale]">
                        </h5>

                        <div class="mt-2"
                             v-if="props.isTreatment">
                            <div class="mt-2">
                                <p class="mb-1 d-inline-block mr-1">
                                    {{ parent.$t("message.basket.treatments.options") }}
                                </p>
                                <h5 class="d-inline-block">
                                    <span class="badge badge-dark font-weight-light mr-2"
                                          v-for="sku in skus"
                                          v-bind:key="sku._id">
                                        {{ sku.name[parent.$i18n.locale] }}
                                    </span>
                                </h5>
                            </div>
                            <div class="mb-1">
                                <p class="d-inline-block mb-0 mr-1">
                                    {{ parent.$t("message.basket.treatments.date") }}
                                </p>
                                <h5 class="d-inline-block mb-0">
                                    {{ session }} {{ date | dateFnsFormatter("yyyy/M/d") }}
                                </h5>
                            </div>
                            <div class="mb-1">
                                <p class="d-inline-block mb-0 mr-1">
                                    {{ parent.$t("message.basket.treatments.branch") }}
                                </p>
                                <h5 class="d-inline-block mb-0">
                                    {{ branch.address[parent.$i18n.locale] }}
                                </h5>
                            </div>
                        </div>

                        <div v-else>
                            <p class="text-right text-md-left mb-0"
                               v-html="subtitle[parent.$i18n.locale]">
                            </p>
                            <div class="mt-2 text-right text-md-left">
                                <p class="d-inline-block mr-1">
                                    {{ parent.$t("message.basket.unit-price") }}
                                </p>
                                <h5 class="d-inline-block">
                                    <small>HK$</small>
                                    {{ prices[0].hkd | currency("", 0) }}
                                </h5>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4 d-flex flex-column">
                        <div class="flex-2 d-flex flex-column justify-content-center align-items-end align-items-md-center mb-1 mb-md-0"
                             v-if="!props.isTreatment">
                            <p class="mb-1">
                                {{ parent.$t("message.basket.quantity") }}
                            </p>
                            <div class="input-group input-group-sm justify-content-end justify-content-md-center font-weight-bold"
                                 style="max-width: 120px; font-size: 20px;">
                                {{ quantity }}
                            </div>
                        </div>

                        <div class="flex-2 d-flex flex-column justify-content-center align-items-end align-items-md-center mb-1 mb-md-0">
                            <p class="mb-0">
                                {{ parent.$t("message.basket.sub-total") }}
                            </p>

                            <h5 class="mb-0"
                                v-if="props.isTreatment">
                                <small>HK$</small>
                                {{ skus.reduce((acc, sku) => acc += sku.hkd, 0) | currency("", 0) }}
                            </h5>
                            <h5 class="mb-0"
                                v-else>
                                <small>HK$</small>
                                {{ quantity * prices[0].hkd | currency("", 0) }}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "OrderItems",
        props: {
            "items": {
                type: Array,
                required: true,
            },

            "isTreatment": {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss" scoped>
    ul {
        & > li {
            border-bottom: 1px solid rgba(0, 0, 0, .125);

            &:first-child {
                border-top: 1px solid rgba(0, 0, 0, .125);
            }

            .item-image-wrapper {
                min-width: 120px;

                & > .item-image {
                    height: 150px;
                    max-width: 100%;
                }
            }
        }
    }
</style>

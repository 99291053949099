import { render, staticRenderFns } from "./PaymentMethod.vue?vue&type=template&id=fdf7c38a&scoped=true&functional=true&"
import script from "./PaymentMethod.vue?vue&type=script&lang=js&"
export * from "./PaymentMethod.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "fdf7c38a",
  null
  
)

export default component.exports
<template functional>
    <div class="row mb-3 d-flex justify-content-center">
        <div class="col-md-3 text-left text-md-right">
            <strong>
                {{ parent.$t("message.checkout.delivery-method") }}
            </strong>
        </div>
        <div class="col-md-7">
            <h5>
                <span v-if="props.deliveryType === 'standard'">
                    Standard Shipping
                </span>
                <span v-else-if="props.deliveryType === 'expedited'">
                    Expedited Shipping
                </span>
            </h5>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderDeliveryMethod",
        props: {
            "deliveryType": {
                type: String,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

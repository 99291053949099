<template>
    <div class="row no-gutters mb-4">
        <div class="col-md-9"></div>
        <div class="col-md-3">
            <table>
                <tr>
                    <td class="text-right">
                        <p class="d-inline-block mb-0 mr-3">
                            {{ $t("message.basket.delivery") }}
                        </p>
                    </td>
                    <td class="text-left">
                        <h5 class="d-inline-block mb-0">
                            <small>HK$</small>
                            {{ deliveryTotal | currency("", 2) }}
                        </h5>
                    </td>
                </tr>

                <tr v-if="discountTotal">
                    <td class="text-right">
                        <p class="d-inline-block mb-0 mr-3">
                            {{ $t("message.basket.promotion") }}
                        </p>
                    </td>
                    <td class="text-left">
                        <h5 class="d-inline-block text-danger text-nowrap mb-0">
                            -<small>HK$</small>{{ discountTotal | currency("", 2) }}
                        </h5>
                    </td>
                </tr>

                <tr>
                    <td class="text-right">
                        <p class="d-inline-block mb-0 mr-3">
                            {{ $t("message.basket.total") }}
                        </p>
                    </td>
                    <td class="text-left">
                        <h5 class="d-inline-block mb-0">
                            <small>HK$</small>{{ orderTotal | currency("", 2) }}
                        </h5>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderTotal",
        props: {
            "deliveryTotal": {
                type: Number,
                required: true,
            },
            "discountTotal": {
                type: Number,
            },
            "orderTotal": {
                type: Number,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

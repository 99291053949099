<template>
    <section class="checkout-completion">
        <div class="container pt-4">
            <order-contact v-bind:email="order.delivery.email"
                           v-bind:phone="order.delivery.phone">
            </order-contact>

            <order-delivery-address v-if="$route.params.type === 'products'"
                                    v-bind:delivery="order.delivery">
            </order-delivery-address>

            <order-delivery-method v-if="$route.params.type === 'products'"
                                   v-bind:delivery-type="order.delivery.deliveryType">
            </order-delivery-method>

            <order-payment-method v-bind:payment="order.payment"></order-payment-method>

            <order-items v-bind:is-treatment="$route.params.type !== 'products'"
                         v-bind:items="order.items">
            </order-items>

            <order-total v-bind:delivery-total="deliveryTotal"
                         v-bind:discount-total="discountTotal"
                         v-bind:order-total="orderTotal">
            </order-total>

            <div class="text-center mb-4">
                {{ $t("message.checkout.confirm-email-sent") }} {{ order.delivery.email }}
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <router-link class="btn btn-dark btn-block btn-confirm"
                             to="/">
                    {{ $t("message.checkout.confirm") }}
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
    import OrderContact from "./Completion/Contact";
    import OrderDeliveryAddress from "./Completion/DeliveryAddress";
    import OrderPaymentMethod from "./Completion/PaymentMethod";
    import OrderDeliveryMethod from "./Completion/DeliveryMethod";
    import OrderItems from "./Completion/OrderItems";
    import OrderTotal from "./Completion/Total";

    export default {
        name: "Completion",
        components: {
            OrderTotal,
            OrderItems,
            OrderDeliveryMethod,
            OrderPaymentMethod,
            OrderDeliveryAddress,
            OrderContact,
        },
        props: {
            "orderId": {
                type: String,
                required: true,
            },
        },
        data () {
            return {

            }
        },
        computed: {
            deliveryClasses () {
                return this.$store.state.mall.shop.deliveryClasses;
            },
            order () {
                const orderMap = {
                    "products": "shop",
                    "treatments": "salon"
                };

                if (this.orderId) {
                    const target = this.$store.state.mall
                        .orders[
                            orderMap[this.$route.params.type]
                        ]
                        .find(
                            order => order._id === this.orderId
                        );

                    if (target) {
                        return target;
                    }

                    return null;
                }

                return {
                    items: [],
                    delivery: {},
                    payment: {}
                };
            },
            deliveryTotal () {
                return this.order?.deliveryTotal ?? "";
            },
            discountTotal () {
                return this.order?.discountTotal ?? "";
            },
            orderTotal () {
                return this.order?.orderTotal ?? "";
            },
        },
        methods: {
            fetchOrders () {
                if (
                    this.order &&
                    this.order.status === "pending"
                ) {
                    this.$store
                        .dispatch(
                            "mall/loadOrders"
                        );
                }
            },
        },
        mounted () {
            this.fetchOrders();
        },
    }
</script>

<style lang="scss" scoped>
    .checkout-completion {
        .btn-confirm {
            max-width: 300px;
        }
    }
</style>

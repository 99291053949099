<template functional>
    <div class="row mb-3 d-flex justify-content-center">
        <div class="col-md-3 text-left text-md-right">
            <strong>
                {{ parent.$t("message.checkout.delivery-address") }}
            </strong>
        </div>
        <div class="col-md-7">
            <h4>
                {{ props.delivery.address.firstName }} {{ props.delivery.address.lastName }}
            </h4>
            <p class="mb-1">
                {{ props.delivery.address.addressLine1 }}
            </p>
            <p class="mb-1">
                {{ props.delivery.address.addressLine2 }}
            </p>
            <p class="mb-1">
                <span v-if="props.delivery.address.postcode">
                    {{ props.delivery.address.postcode }},
                </span>
                <span v-if="props.delivery.address.country.name">
                    {{ props.delivery.address.country.name[parent.$i18n.locale] }}
                </span>
                <span v-else>
                    {{ props.delivery.address.country }}
                </span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderDeliveryAddress",
        props: {
            "delivery": {
                type: Object,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

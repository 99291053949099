<template functional>
    <div class="row mb-5 d-flex justify-content-center">
        <div class="col-md-3 text-left text-md-right">
            <strong>
                {{ parent.$t("message.checkout.payment-method") }}
            </strong>
        </div>

        <div class="col-md-7"
             v-if="props.payment && ['cards', 'payment_request_api'].includes(props.payment.paymentMethod)">
            <p class="mb-1">
                <strong>{{ props.payment.card.brand }} {{ props.payment.card.last4 }}</strong>
            </p>
            <p class="mb-1">
                Exp: {{ props.payment.card.exp_month }} / {{ props.payment.card.exp_year }}
            </p>
        </div>
        <div class="col-md-7"
             v-if="props.payment && props.payment.paymentMethod === 'alipay'">
            <p class="mb-1">
                <strong>
                    {{ parent.$t("message.checkout.payment-methods.alipay") }}
                </strong>
            </p>
        </div>
        <div class="col-md-7"
             v-if="props.payment && props.payment.paymentMethod === 'wechat_pay'">
            <p class="mb-1">
                <strong>
                    {{ parent.$t("message.checkout.payment-methods.wechat-pay") }}
                </strong>
            </p>
        </div>
        <div class="col-md-7"
             v-if="props.payment && props.payment.paymentMethod === 'paypal'">
            <p class="mb-1">
                <strong>
                    {{ parent.$t("message.checkout.payment-methods.paypal") }}
                </strong>
            </p>
        </div>
        <div class="col-md-7"
             v-if="props.payment && props.payment.paymentMethod === 'pay_on_site'">
            <p class="mb-1">
                <strong>
                    {{ parent.$t("message.checkout.payment-methods.pay-on-site") }}
                </strong>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderPaymentMethod",
        props: {
            "payment": {
                type: Object,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

<template functional>
    <div class="row mb-3 d-flex justify-content-center">
        <div class="col-md-3 text-left text-md-right">
            <strong>
                {{ parent.$t("message.checkout.contact") }}
            </strong>
        </div>
        <div class="col-md-7">
            <p class="mb-0">
                {{ props.email }}
            </p>
            <p class="mb-0">
                {{ props.phone }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderContact",
        props: {
            "email": {
                type: String,
                required: true,
            },

            "phone": {
                type: String,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
